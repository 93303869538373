import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';

// import router from '@/router';
import store from '@/store';

import { Message } from 'element-ui';

// use HTTP schema for local development and testing environment
const hasuraURL = 'https://elecond.hasura.app/api/rest';

const $api = axios.create({
  baseURL: hasuraURL,
  withCredentials: true,
});

$api.interceptors.response.use(
  (res) => {
    if (res.data.errors) {
      const error = new Error(
        `Connection error to DB - ${res.data.errors[0].message}`
      );
      error.response = res;

      Message({
        type: 'error',
        message: 'Ошибка сервера попробуйте еще раз',
      });

      throw error;
    }

    return res;
  },
  (error) => {
    Message({
      type: 'error',
      message: 'Connection error',
    });
    return Promise.reject(error);
  }
);

// set role before request send
$api.interceptors.request.use(
  async function (config) {
    const { token, profile } = store.state;
    config.headers = {
      Authorization: 'Bearer ' + token,
      'x-hasura-user-id': profile.id,
      'x-hasura-allowed-roles': profile.roles,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const postmarkURL = 'https://itillect.ru';

const $mail = axios.create({
  baseURL: postmarkURL,
});

const $hasura = axios.create({
  baseURL: 'https://elecond.hasura.app/v1/graphql',
  withCredentials: true,
});

$hasura.interceptors.response.use(
  (res) => {
    if (res.data.errors) {
      const error = new Error(
        `Connection error to DB - ${res.data.errors[0].message}`
      );
      error.response = res;

      Message({
        type: 'error',
        message: 'Ошибка сервера попробуйте еще раз',
      });

      throw error;
    }

    return res;
  },
  (error) => {
    Message({
      type: 'error',
      message: 'Connection error',
    });
    return Promise.reject(error);
  }
);

// set role before request send
$hasura.interceptors.request.use(
  async function (config) {
    const { token, profile } = store.state;
    config.headers = {
      Authorization: 'Bearer ' + token,
      'x-hasura-user-id': profile.id,
      'x-hasura-allowed-roles': profile.roles,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const $db = axios.create({
  baseURL: 'https://api.datahi.tech/',
});

Vue.use(VueAxios, { $api, $mail, $hasura, $db });
