import { Authorizer } from '@authorizerdev/authorizer-js';

const $auth = new Authorizer({
  authorizerURL: 'https://authorizer-production-8a72.up.railway.app/',
  redirectURL: '/',
  clientID: '241f6858-7d7a-4240-bbb0-d6e929010265',
});

export default {
  namespaced: true,
  isRegistered: false,
  state: {
    users: [],
    roles: [],
  },
  actions: {
    deleteRole({ commit }, id) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .delete(`/roles/${id}`)
          .then(() => {
            commit('DELETE_ROLE', id);
            resolve();
          })
          .catch(reject);
      });
    },
    addRole({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.roles.length == 12) reject();
        const key = `role${state.roles.length - 1}`;
        this._vm.$api
          .put('/roles', { key })
          .then(({ data: { insert_roles } }) => {
            commit('SET_ROLE', insert_roles.returning[0]);
            resolve(insert_roles.returning[0]);
          })
          .catch(reject);
      });
    },
    fetchRoles({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get('/roles')
          .then(({ data }) => {
            commit('SET_ROLES', data);
            resolve();
          })
          .catch(reject);
      });
    },
    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get('/users')
          .then(({ data }) => {
            commit('SET_USERS', data);
            resolve();
          })
          .catch(reject);
      });
    },
    editUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        console.log(data);
        this._vm.$api
          .post(`/users/${data.id}`, {
            email: data.email,
            given_name: data.name,
            family_name: data.fathername,
            middle_name: data.surname,
            phone_number: data.phone,
            roles: data.roles.join(','),
          })
          .then(({ data: { update_authorizer_users } }) => {
            commit('EDIT_USER', update_authorizer_users.returning[0]);
            resolve();
          })
          .catch(reject);
      });
    },
    addUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        const pass = this._vm.$givmehash(8);
        const email = data.email;
        const name = `${data.surname} ${data.name} ${data.fathername}`;
        $auth
          .signup({
            password: pass,
            confirm_password: pass,
            email: data.email,
            given_name: data.name,
            family_name: data.surname,
            middle_name: data.fathername,
            phone_number: data.phone,
            roles: data.roles,
          })
          .then((data) => {
            const { user } = data;
            user.roles = user.roles.join(',');
            commit('SET_USER', { user });

            this._vm.$mail.get('/datahiemail', {
              params: {
                email,
                name,
                pass,
              },
            });

            resolve();
          })
          .catch(reject);
      });
    },
    deleteUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .delete(`/users/${id}`)
          .then(() => {
            commit('DELETE_USER', id);
            resolve();
          })
          .catch(reject);
      });
    },
    updateRole({ commit }, { role }) {
      return new Promise((resolve, reject) => {
        delete role.key;
        this._vm.$api
          .post(`/roles/${role.id}`, role)
          .then(({ data: { update_roles } }) => {
            resolve();
            setTimeout(
              () => commit('UPD_ROLE', update_roles.returning[0]),
              200
            );
          })
          .catch(reject);
      });
    },
  },
  mutations: {
    SET_ROLES: (state, { roles }) => (state.roles = roles),
    SET_USERS: (state, { users }) => (state.users = users),
    SET_USER: (state, { user }) => state.users.push(user),
    DELETE_USER: (state, id) => {
      const index = state.users.findIndex((em) => em.id === id);
      state.users.splice(index, 1);
    },
    EDIT_USER: (state, user) => {
      const index = state.users.findIndex((em) => em.id === user.id);
      state.users.splice(index, 1, user);
    },
    UPD_ROLE: (state, role) => {
      const index = state.roles.findIndex((em) => em.id === role.id);
      state.roles.splice(index, 1, role);
    },
    SET_ROLE: (state, role) => state.roles.push(role),
    DELETE_ROLE: (state, id) => {
      const index = state.roles.findIndex((em) => em.id === id);
      state.roles.splice(index, 1);
    },
  },
};
