export default {
  namespaced: true,
  isRegistered: false,
  state: {
    isModalOpen: false,
    promise: {},
    options: {},
    content: null,
    disabled: null,
    keys: [
      {
        key: 'user',
        value: 'UserForm',
      },
      {
        key: 'dictionary',
        value: 'DictionaryForm',
      },
    ],
    currentKey: null,
  },
  actions: {
    close: ({ commit }, payload) => commit('CLOSE', payload),
    open: ({ commit }, payload) => commit('OPEN', payload),
    resolve: ({ commit, state }, { data }) =>
      new Promise((resolve, reject) => {
        state.promise.resolve({ data, resolve, reject });
      })
        .then(() => commit('CLOSE'))
        .catch(state.promise.reject),
  },
  mutations: {
    CLOSE: (state) => {
      state.isModalOpen = false;

      state.currentKey = null;
      state.content = null;
      state.disabled = null;
      state.options = null;
      state.promise = {};
    },
    OPEN: (
      state,
      { key, resolve, reject, data = null, options = null, disabled = null }
    ) => {
      state.currentKey = key;

      state.content = data;
      state.disabled = disabled;
      state.options = options;

      state.promise.resolve = resolve;
      state.promise.reject = reject;

      state.isModalOpen = true;
    },
  },
  getters: {
    component: (state) =>
      state.keys.find((x) => x.key === state.currentKey)?.value ?? null,
    label(state) {
      if (state.options?.label) return state.options.label;
      return state.keys.find((x) => x.key === state.currentKey)?.label ?? null;
    },
  },
};
