<template>
  <el-form
    class="employee-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    @validate="onValidate"
  >
    <el-form-item>
      <el-col :span="11">
        <el-form-item label="Бренд" prop="brand">
          <el-input
            v-model="form.brand"
            placeholder="Название бренда"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-form-item label="Артикул *" prop="art">
          <el-input v-model="form.art" placeholder="Артикул"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="Синонимы" prop="synonims">
      <el-select
        v-model="form.synonims"
        multiple
        filterable
        allow-create
        placeholder="Введи последовательно все синонимы для артикула"
      >
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-col :span="11">
        <el-form-item label="Напряжение, В" prop="p1">
          <el-input v-model="form.p1" placeholder="Напряжение, В"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-form-item label="Емкость, Ф" prop="p2">
          <el-input v-model="form.p2" placeholder="Емкость, Ф"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-col :span="11">
        <el-form-item label="Тип выводов" prop="p3">
          <el-select v-model="form.p3" placeholder="Выберите">
            <el-option
              v-for="item in typeOutput"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-form-item label="Тип конденсатора" prop="p4">
          <el-select v-model="form.p4" placeholder="Выберите">
            <el-option
              v-for="item in typeCon"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button
        type="success"
        @click="save"
        v-loading="load"
        :disabled="!isFormValid"
        >{{ options.buttonLabel }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'DictionaryForm',
  props: {
    content: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      email: '',
      form: {
        brand: '',
        art: '',
        synonims: [],
        p1: '',
        p2: '',
        p3: '',
        p4: '',
      },
      load: false,
      rules: {
        art: [{ required: true, message: 'Поле не может быть пустым' }],
      },
      validFlags: {
        art: false,
      },
    };
  },
  created() {
    if (!this.content) return;

    Object.assign(this.form, this.content);

    for (let key in this.validFlags) this.validFlags[key] = true;
  },
  computed: {
    ...mapState('modal', ['options']),
    ...mapState('dictionary', ['typeOutput', 'typeCon']),
    isFormValid() {
      return Object.values(this.validFlags).every((flag) => flag);
    },
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) this.form.id = this.content.id;
      this.form.synonims = JSON.stringify(this.form.synonims);
      this.$store
        .dispatch('modal/resolve', { data: this.form })
        .finally(() => (this.load = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-form {
  &-name {
    display: flex;
    flex-direction: column;
  }
}

.employee-form::v-deep {
  .el-form-item__label {
    text-align: left;
  }
}
</style>
