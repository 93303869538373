import { Authorizer } from '@authorizerdev/authorizer-js';

const $auth = new Authorizer({
  authorizerURL: 'https://authorizer-production-8a72.up.railway.app/',
  redirectURL: '/',
  clientID: '241f6858-7d7a-4240-bbb0-d6e929010265',
});

export default {
  namespaced: true,
  isRegistered: false,
  actions: {
    login({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        $auth
          .login(payload)
          .then(({ access_token }) => {
            const token = access_token;
            dispatch('setToken', { token }, { root: true });
            resolve();
          })
          .catch(reject);
      });
    },
    getProfile({ dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        const { token } = rootState;
        $auth
          .getProfile({
            Authorization: `Bearer ${token}`,
          })
          .then((data) => {
            dispatch('setProfile', data, { root: true });
            resolve();
          })
          .catch((err) => {
            //if (err.message === 'Error: unauthorized') {
            dispatch('logout', null, { root: true });
            //}
            reject(err);
          });
      });
    },
    updateProfile({ dispatch }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        this._vm.$api
          .patch(`/profile/${payload.id}`, {
            family_name: payload.family_name,
            middle_name: payload.middle_name,
            given_name: payload.given_name,
            phone_number: payload.phone_number,
          })
          .then(({ data }) => {
            const profile = data.update_authorizer_users.returning[0];
            dispatch('setProfile', profile, { root: true });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeEmail({ dispatch, rootState }, { email }) {
      return new Promise((resolve, reject) => {
        const { token } = rootState;
        $auth
          .updateProfile(
            {
              email,
            },
            {
              Authorization: `Bearer ${token}`,
            }
          )
          .then(() => {
            dispatch('logout', null, { root: true });
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    changePassword({ dispatch, rootState }, { oldPassword, password }) {
      return new Promise((resolve, reject) => {
        const { token } = rootState;
        $auth
          .updateProfile(
            {
              old_password: oldPassword,
              new_password: password,
              confirm_new_password: password,
            },
            {
              Authorization: `Bearer ${token}`,
            }
          )
          .then(() => {
            dispatch('logout', null, { root: true });
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
