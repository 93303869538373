<template>
  <el-form
    class="employee-form"
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="load"
    @validate="onValidate"
  >
    <el-form-item>
      <el-col :span="11">
        <el-form-item label="Email *" prop="email">
          <el-input
            v-model="form.email"
            placeholder="Электронная почта"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="1">
        <el-form-item label="Телефон" prop="phone">
          <el-input v-model="form.phone" placeholder="Телефон"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="ФИО *" class="employee-form-name">
      <el-col :span="7">
        <el-form-item prop="surname">
          <el-input v-model="form.surname" placeholder="Фамилия"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="7" :offset="1">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="Имя"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8" :offset="1">
        <el-form-item prop="fathername">
          <el-input v-model="form.fathername" placeholder="Отчество"></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item label="Роль">
      <el-select v-model="form.roles" multiple placeholder="Select">
        <el-option
          v-for="item in roles"
          :key="item.key"
          :label="item.name"
          :value="item.key"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="form.email !== email">
      <el-alert type="warning" :closable="false">
        <slot name="title">
          <div class="svg-icon" v-html="$icons['alert-triangle']"></div>
          На указанный email будет выслано приглашение с реквизитами доступа в
          систему
        </slot>
      </el-alert>
    </el-form-item>
    <el-form-item>
      <el-button
        type="success"
        @click="save"
        v-loading="load"
        :disabled="!isFormValid"
        >{{ options.buttonLabel }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'UserForm',
  props: {
    content: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      email: '',
      form: {
        name: '',
        surname: '',
        fathername: '',
        phone: '',
        email: '',
        roles: ['user'],
      },
      load: false,
      rules: {
        name: [{ required: true, message: 'Поле не может быть пустым' }],
        surname: [{ required: true, message: 'Поле не может быть пустым' }],
        email: [{ validator: this.validateEmail, trigger: 'change' }],
      },
      validFlags: {
        name: false,
        surname: false,
        email: false,
      },
    };
  },
  created() {
    if (!this.content) return;

    this.form.name = this.content.given_name;
    this.form.surname = this.content.middle_name;
    this.form.fathername = this.content.family_name;
    this.form.phone = this.content.phone_number;
    this.form.email = this.content.email;
    this.email = this.content.email;
    this.form.roles = this.content.roles.split(',');

    for (let key in this.validFlags) this.validFlags[key] = true;
  },
  computed: {
    ...mapState('modal', ['options']),
    ...mapState('users', ['users', 'roles']),
    isFormValid() {
      return Object.values(this.validFlags).every((flag) => flag);
    },
  },
  methods: {
    onValidate(field, isValid) {
      this.validFlags[field] = isValid;
    },
    save() {
      this.load = true;
      if (this.content) this.form.id = this.content.id;
      this.$store
        .dispatch('modal/resolve', { data: this.form })
        .finally(() => (this.load = false));
    },
    validateEmail(_, value, callback) {
      const pattern = /.+@.+\..+/i;
      if (value === '') {
        callback(new Error('Поле не может быть пустым'));
        return false;
      }
      if (!pattern.test(value)) {
        callback(new Error('Email введен некорректно'));
        return false;
      }
      if (this.users.find((x) => x.email === value)) {
        callback(new Error('Пользователь с таким email уже существует'));
        return false;
      }
      callback();
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-form {
  &-name {
    display: flex;
    flex-direction: column;
  }
}

.employee-form::v-deep {
  .el-form-item__label {
    text-align: left;
  }
}
</style>
