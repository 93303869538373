import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';

Vue.use(VueRouter);

const useComponent = (component) => () => import(`./views/${component}.vue`);

const routes = [
  {
    path: '/',
    name: 'home',
    //redirect: 'report',
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth',
    name: 'auth',
    component: useComponent('Auth/auth'),
    meta: {
      layout: 'auth',
      title: 'Авторизация',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: useComponent('profile'),
    meta: {
      title: 'Настройки профиля',
      auth: true,
    },
  },
  {
    path: '/source',
    name: 'source',
    component: useComponent('parsing/source'),
    meta: {
      title: 'Исходный файл',
      auth: true,
      menu: true,
      menuGroup: 'Парсинг',
      icon: 'file',
      label: 'Исходный файл',
    },
  },
  {
    path: '/filters',
    name: 'filters',
    component: useComponent('filters'),
    meta: {
      title: 'Фильтры',
      auth: true,
      menu: true,
      menuGroup: 'Парсинг',
      icon: 'filter',
      label: 'Фильтры',
    },
  },
  {
    path: '/filters/:id',
    name: 'filter',
    meta: {
      title: 'Фильтр',
      auth: true,
      menu: false,
      parent: 'filters',
    },
    component: useComponent('filter'),
  },
  {
    path: '/dictionary',
    name: 'dictionary',
    component: useComponent('dictionary'),
    meta: {
      title: 'Справочник',
      auth: true,
      menu: true,
      menuGroup: 'Справочники',
      icon: 'book',
      label: 'Справочник',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: useComponent('admin/users'),
    meta: {
      title: 'Пользователи',
      auth: true,
      menu: true,
      menuGroup: 'Настройки',
      icon: 'users',
      label: 'Пользователи',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: useComponent('admin/roles'),
    meta: {
      title: 'Роли',
      auth: true,
      menu: true,
      menuGroup: 'Настройки',
      icon: 'user-check',
      label: 'Роли',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  user: null,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

const getAppData = () => {
  store.dispatch('getData');
};

router.beforeEach((to, from, next) => {
  const { token, profile } = store.state;

  if (!to.meta.auth) next();
  if (to.meta.auth === true && !token) router.push({ name: 'auth' });

  if (!to.meta.auth && token) {
    router.push({ name: '/' });
  }
  if (to.meta.auth === true && !profile) {
    getAppData();
  }

  next();
});

export default router;
