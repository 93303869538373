import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import modal from './modal';
import auth from './auth';
import users from './users';

export default new Vuex.Store({
  modules: {
    modal,
    auth,
    users,
  },
  state: {
    profile: null,
    token: localStorage.getItem('token') || null,
    isLoading: false,
    loadPercent: 0,
    file: {},
    upload: {
      counter: Number(localStorage.getItem('upload:counter')) ?? 0,
      time: localStorage.getItem('upload:time') ?? 0,
    },
  },
  mutations: {
    SET_UPLOAD_COUNTER(state, count) {
      localStorage.setItem('upload:counter', count);
      state.upload.counter = count;
    },
    SET_TOKEN: (state, { token }) => {
      state.token = token;
      if (token) {
        localStorage.setItem('token', token);
        return;
      }

      localStorage.removeItem('token');
      location.reload();
    },
    SET_LOAD: (state, payload) => (state.isLoading = payload),
    SET_PROFILE: (state, payload) => (state.profile = payload),
    SET_FILE: (state, data) => {
      state.file = data ?? null;
    },
    DELETE_FILE: (state) => (state.file = null),
  },
  actions: {
    async saveFile({ state, commit }, { objects, file, parsed }) {
      try {
        state.upload.counter++;
        state.upload.time = Date.now();
        localStorage.setItem('upload:counter', state.upload.counter);
        localStorage.setItem('upload:time', state.upload.time);

        console.log('!2');

        await this._vm.$api.delete('/file');
        await this._vm.$api.delete('/parsed');

        let it = 150;
        let size = parseInt(objects.length / it);

        for (let i = 0; i <= size; i++) {
          await this._vm.$api.put('/file', {
            objects: objects.slice(i * it, (i + 1) * it),
          });
          state.loadPercent = (((i + 1) / (size + 1)) * 100) / 2;
        }

        if (parsed.length > 0) {
          it = 500;
          size = parseInt(parsed.length / it);

          for (let i = 0; i <= size; i++) {
            await this._vm.$api.put('/parsed', {
              objects: parsed.slice(i * it, (i + 1) * it),
            });

            state.loadPercent = 50 + (((i + 1) / (size + 1)) * 100) / 2;
          }
        } else {
          state.loadPercent = 100;
        }

        await this._vm.$db.post('/', {
          action: 'set-data',
        });

        await this._vm.$api.put('/fdata', { data: file });
        file.created_at = new Date();
        commit('SET_FILE', file);
      } catch (error) {
        throw new Error();
      }
    },
    getFile({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .get(`/fdata`)
          .then(({ data: { fileData } }) => {
            let obj = null;
            if (fileData[0]) {
              obj = {
                ...fileData[0]?.data,
                created_at: fileData[0]?.created_at ?? '',
              };
            }
            commit('SET_FILE', obj);
            resolve();
          })
          .catch(reject);
      });
    },
    deleteFile({ commit }) {
      return new Promise((resolve, reject) => {
        this._vm.$api
          .delete(`/fdata`)
          .then(() => {
            commit('DELETE_FILE');
            resolve();
          })
          .catch(reject);
      });
    },
    setLoad: ({ commit }, payload) => commit('SET_LOAD', payload),
    async getData({ dispatch }) {
      await dispatch('auth/getProfile');
      await dispatch('users/fetchRoles');
    },
    setToken: ({ commit }, payload) => commit('SET_TOKEN', payload),
    setProfile: ({ commit }, payload) => commit('SET_PROFILE', payload),
    logout({ commit }) {
      commit('SET_TOKEN', { token: null });
      this._vm.$router.push({ name: 'auth' });
    },
  },

  getters: {
    permissions(state) {
      if (!state?.profile) return false;
      if (!state?.users?.roles) return false;

      const { roles } = state.profile;

      const perm = {};
      roles.forEach((role) => {
        const drole = state.users.roles.find((x) => x.key == role);
        if (!drole) return;
        for (let key in drole.config) {
          const trig = drole.config[key] == 1 ? true : false;
          perm[key] = perm[key] || trig;
        }
      });
      return perm;
    },
  },
});
